import { isPlatform } from '@ionic/react'
import { Drivers, Storage } from '@ionic/storage'
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import { DatabaseName } from '.'

const isNative = isPlatform('ios') || isPlatform('android')

export const storage = new Storage({
  name: DatabaseName,
  driverOrder: [isNative ? CordovaSQLiteDriver._driver : Drivers.IndexedDB, Drivers.LocalStorage],
})
storage.create()

export enum StorageEnum {
  TIMER = 'TIMER',
  TIMER_CONFIG = 'TIMER_CONFIG',
  CONFIG_THEME = 'CONFIG_THEME',
}
