import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css' // default theme
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonLoading,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import { alarmOutline, ribbonOutline, settingsOutline } from 'ionicons/icons'
import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect, Route } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { DebugObserver } from './components/atoms/debug/DebugObserver'
import { ContinueTimerActionSheet } from './components/organisms/action/ContinueTimerActionSheet'
import { AppModule } from './components/organisms/AppModule'
import './index.css'
import { Achivement } from './pages/Achivement'
import Home from './pages/Home'
import Login from './pages/Login'
import Settings from './pages/Settings'
/* Theme variables */
import './theme/variables.css'

setupIonicReact()

const App: React.FC = () => (
  <ErrorBoundary fallback={<h1>Something went wrong.</h1>}>
    <RecoilRoot>
      <AmplifyProvider>
        <Authenticator.Provider>
          <ErrorBoundary fallback={<h1>Global state error occured.</h1>}>
            <Suspense fallback={<></>}>
              <AppModule />
              <DebugObserver ignoreList={['TimerState/tickState']} />
            </Suspense>
          </ErrorBoundary>
          <Suspense fallback={<IonLoading isOpen message={'Please wait...'} />}>
            <IonApp>
              <IonReactRouter>
                <IonTabs>
                  <IonRouterOutlet>
                    <Route exact path="/login">
                      <Login />
                    </Route>
                    <Route exact path="/home">
                      <Home />
                    </Route>
                    <Route exact path="/settings">
                      <Settings />
                    </Route>
                    <Route exact path="/achivement">
                      <Achivement />
                    </Route>
                    <Route exact path="/">
                      <Redirect to="/home" />
                    </Route>
                  </IonRouterOutlet>
                  <IonTabBar slot="bottom">
                    <IonTabButton tab="achivement" href="/achivement">
                      <IonIcon icon={ribbonOutline} />
                      <IonLabel>Achivement</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="home" href="/home">
                      <IonIcon icon={alarmOutline} />
                      <IonLabel>Timer</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="settings" href="/settings">
                      <IonIcon icon={settingsOutline} />
                      <IonLabel>Settings</IonLabel>
                    </IonTabButton>
                  </IonTabBar>
                </IonTabs>
                {/* Global component */}
                <ContinueTimerActionSheet />
              </IonReactRouter>
            </IonApp>
          </Suspense>
        </Authenticator.Provider>
      </AmplifyProvider>
    </RecoilRoot>
  </ErrorBoundary>
)

export default App
