import { useIonToast } from '@ionic/react'
import { useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { tickSelector } from '../recoil/timer'
import { syncTimerActions, timerActions, timerBell } from '../recoil/timerActions'
import { logger } from '../utils/logger'

export const useTimer = () => {
  const { startTimer, stopTimer, continueTimer } = useRecoilValue(timerActions)

  return {
    startTimer,
    stopTimer,
    continueTimer,
  }
}

export const useSyncTimerActions = () => {
  const actions = useRecoilValue(syncTimerActions)

  return {
    syncTimers: actions?.syncTimers,
  }
}

export const useTimerAutomation = () => {
  const tick = useRecoilValue(tickSelector('seconds'))
  const minutesTick = useRecoilValue(tickSelector('minutes'))
  const { ringBell } = useRecoilValue(timerBell({ audioType: 'bell', time_basis: 'end_time' }))
  const { ringBell: ringTick } = useRecoilValue(timerBell({ audioType: 'tick', time_basis: 'start_time' }))
  const { continueTimer, checkTimer } = useRecoilValue(timerActions)
  const [present] = useIonToast()
  const reqRef = useRef(false)

  useEffect(() => {
    if (reqRef.current) {
      return
    }
    reqRef.current = true
    continueTimer(tick)
      .catch((err) => {
        if (err instanceof Error) {
          return present({
            message: err.message,
            duration: 3000,
            position: 'bottom',
            color: 'danger',
          })
        }
      })
      .finally(() => {
        reqRef.current = false
      })
  }, [continueTimer, present, tick])

  useEffect(() => {
    const sec = 40 * Math.random()
    window.setTimeout(() => {
      checkTimer()
    }, sec * 1000)
    logger.debug(`Check timer after ${sec}sec`)
  }, [checkTimer, minutesTick])

  useEffect(() => {
    ringBell?.(tick)
    ringTick?.(tick)
  }, [ringBell, ringTick, tick])
}
