import { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { timerGaugeSelector } from '../../../recoil/timer'
import type { TimerViewport } from '../../../types/viewport'
import { NormalGauge } from '../../atoms/frames/normal/NormalGauge'

export interface TimerGaugeProps {
  viewport: TimerViewport
  radius: number
}

export const TimerGauge: FC<TimerGaugeProps> = ({ viewport, radius }) => {
  const gauge = useRecoilValue(timerGaugeSelector)

  if (!gauge) {
    return <></>
  }

  const { interval, from_time, to_time } = gauge

  if (['interval', 'rest'].includes(interval)) {
    return (
      <g className="text-relax">
        <NormalGauge viewport={viewport} radius={radius} start={from_time} end={to_time} />
      </g>
    )
  }
  return (
    <g className="text-focus">
      <NormalGauge viewport={viewport} radius={radius} start={from_time} end={to_time} />
    </g>
  )
}
