import { IonButton, IonContent, IonInput, IonItem, IonItemDivider, IonList, useIonToast } from '@ionic/react'
import React, { FormEvent, useCallback, useEffect, useReducer } from 'react'
import { MainTemplate } from '../components/templates/MainTemplate'
import { useTimeConfig } from '../hooks/useTimerConfig'
import { TimerConfig } from '../recoil/timer'

const reducer = (
  state: TimerConfig,
  action: { type: 'REPLACE'; payload: TimerConfig } | { type: keyof TimerConfig; payload: number }
) => {
  switch (action.type) {
    case 'REPLACE':
      return action.payload as TimerConfig
    default:
      return {
        ...state,
        [action.type]: action.payload,
      }
  }
}

const Settings: React.FC = () => {
  const { timerConfig, saveConfig } = useTimeConfig()
  const [config, dispatch] = useReducer(reducer, timerConfig)
  const [present] = useIonToast()

  useEffect(() => {
    dispatch({ type: 'REPLACE', payload: timerConfig })
  }, [timerConfig])

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      try {
        await saveConfig(config)
      } catch (err) {
        if (err instanceof Error) {
          present({
            message: err.message,
            duration: 3000,
            position: 'bottom',
            color: 'danger',
          })
        }
      }
    },
    [config, present, saveConfig]
  )
  return (
    <MainTemplate title="Settings">
      <IonContent>
        <form onSubmit={handleSubmit}>
          <IonList>
            <IonItemDivider>Focus time</IonItemDivider>
            <IonItem>
              <IonInput
                type="number"
                value={config.focus_minutes}
                placeholder="Enter Number"
                onIonChange={(e) => dispatch({ type: 'focus_minutes', payload: Number(e.detail.value) })}
              ></IonInput>
            </IonItem>
            <IonItemDivider>Interval time</IonItemDivider>
            <IonItem>
              <IonInput
                type="number"
                value={config.interval_minutes}
                placeholder="Enter Number"
                onIonChange={(e) => dispatch({ type: 'interval_minutes', payload: Number(e.detail.value) })}
              ></IonInput>
            </IonItem>
            <IonItemDivider>Rest time</IonItemDivider>
            <IonItem>
              <IonInput
                type="number"
                value={config.rest_minutes}
                placeholder="Enter Number"
                onIonChange={(e) => dispatch({ type: 'rest_minutes', payload: Number(e.detail.value) })}
              ></IonInput>
            </IonItem>
            <IonItemDivider>Reps</IonItemDivider>
            <IonItem>
              <IonInput
                type="number"
                value={config.reps}
                placeholder="Enter Number"
                onIonChange={(e) => dispatch({ type: 'reps', payload: Number(e.detail.value) })}
              ></IonInput>
            </IonItem>
          </IonList>
          <div className="mt-4">
            <IonButton color="primary" size="default" type="submit">
              Save
            </IonButton>
          </div>
        </form>
      </IonContent>
    </MainTemplate>
  )
}

export default Settings
