import { formatDistanceStrict } from 'date-fns'
import { selectorFamily } from 'recoil'
import { historyStorage } from '../storage/history'
import { timerConfigSelector, timerMappter } from './timer'

const sleep = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds))

export const timerHistoryByDateState = selectorFamily({
  key: 'TimerHistory/timerHistoryByDateState',
  get:
    (isoDate: string) =>
    async ({ get, getCallback }) => {
      const config = get(timerConfigSelector)
      const historyIds: readonly string[] = (await historyStorage.get(`${config.id._type}:${isoDate}`)) || []

      const history = (
        await Promise.all(
          historyIds.map(async (id) => {
            const serialized = await historyStorage.get(`${config.id._type}:${id}`)
            return timerMappter(serialized)
          })
        )
      ).filter((t) => t.is_finished)
      const count = history.length
      const passedTime = history.reduce((acc, cur) => acc + cur.end_time - cur.start_time, 0)

      const refresh = getCallback(({ refresh }) => () => {
        refresh(timerHistoryByDateState(isoDate))
      })

      return {
        historyIds,
        count,
        history,
        duration: formatDistanceStrict(0, passedTime, { unit: 'minute' }),
        refresh,
        initialize: async () => {
          await sleep(2000)
          refresh()
        },
      }
    },
})
