import { IonButton, IonIcon, useIonToast } from '@ionic/react'
import { playOutline, stopOutline } from 'ionicons/icons'
import { useCallback } from 'react'
import { useTimer } from '../../../hooks/useTimer'
import { timerActiveSelector } from '../../../recoil/timer'
import { RecoilResolver } from '../utils/RecoilResolver'

export const TimerActions = () => {
  const [present] = useIonToast()
  const { startTimer, stopTimer } = useTimer()

  const handleStart = useCallback(async () => {
    try {
      await startTimer()
    } catch (err) {
      if (err instanceof Error) {
        present({
          message: err.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        })
      }
    }
  }, [present, startTimer])

  const handleStop = useCallback(async () => {
    try {
      await stopTimer()
    } catch (err) {
      if (err instanceof Error) {
        present({
          message: err.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        })
      }
    }
  }, [present, stopTimer])

  return (
    <div className="flex">
      <RecoilResolver state={timerActiveSelector}>
        {(timer) =>
          timer && !timer.is_finished ? (
            <IonButton color="tertiary" onClick={handleStop}>
              <IonIcon slot="start" icon={stopOutline} />
              Stop
            </IonButton>
          ) : (
            <IonButton color="success" onClick={handleStart}>
              <IonIcon slot="start" icon={playOutline} />
              Play
            </IonButton>
          )
        }
      </RecoilResolver>
    </div>
  )
}
