import clsx from 'clsx'

export type CircleTextProps = {
  className?: string
  text?: string
}

export const CircleText = ({ className = 'bg-indigo-600 text-slate-100', text }: CircleTextProps) => {
  const t = text?.charAt(0)

  if (!t) {
    return <></>
  }
  return (
    <div className={clsx('flex justify-center items-center rounded-full w-8 h-8', className)}>{t.toUpperCase()}</div>
  )
}
