import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './utils/auth'

import TagManager from 'react-gtm-module'
import { AuthContentScriptProxy } from './utils/content-script-proxy'

TagManager.initialize({
  gtmId: 'GTM-5LLF8TW',
})

const authProxy = new AuthContentScriptProxy()
authProxy.init()

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // WATCH ME <React.StrictMode> is disabled because of dev server peding issue.
  <>
    <App />
  </>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
