import { isPlatform } from '@ionic/react'
import { Drivers, Storage } from '@ionic/storage'
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import { DatabaseName } from '.'

export const TimerHistoryStoreKey = 'timer_history'

const isNative = isPlatform('ios') || isPlatform('android')

export const historyStorage = new Storage({
  name: DatabaseName,
  storeName: TimerHistoryStoreKey,
  driverOrder: [isNative ? CordovaSQLiteDriver._driver : Drivers.IndexedDB, Drivers.LocalStorage],
})
historyStorage.create()
