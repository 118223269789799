import { formatISO } from 'date-fns'
import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from 'recoil'
import { AwardIcon } from '../components/atoms/svg/AwardIcon'
import { RecoilResolver } from '../components/organisms/utils/RecoilResolver'
import { MainTemplate } from '../components/templates/MainTemplate'
import { tickSelector, timerState } from '../recoil/timer'
import { timerHistoryByDateState } from '../recoil/timerHistory'

export const Achivement = () => {
  const hourTick = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(tickSelector('hours'))
  const timer = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(timerState)

  return (
    <MainTemplate title="Achivement">
      <div className="w-full h-full flex flex-col items-center justify-center">
        <RecoilResolver
          state={timerHistoryByDateState(formatISO(hourTick, { representation: 'date' }))}
          deps={[timer?.id.id]}
        >
          {({ count, duration, refresh }) => (
            <div className="w-4/5 flex flex-col justify-center items-center">
              <div onClick={refresh} className="relative">
                <AwardIcon className="w-full h-full" />
                <div className="absolute inset-0 flex justify-center items-center text-7xl text-white">
                  <span>{count}</span>
                </div>
              </div>
              <span className="text-4xl">{duration}</span>
            </div>
          )}
        </RecoilResolver>
      </div>
    </MainTemplate>
  )
}
