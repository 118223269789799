import secondsToMinutes from 'date-fns/secondsToMinutes'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { useRecoilValue } from 'recoil'
import { APP_TITLE } from '../../../consts/envs'
import { timerTickInfoSelector } from '../../../recoil/timer'

const padLeftStrng = (num: number) => String(num).padStart(2, '0')

export const TimerActionHead = () => {
  const info = useRecoilValue(timerTickInfoSelector)
  const nextSecs = info?.nextSeconds || 0
  const minutes = secondsToMinutes(nextSecs)
  const seconds = nextSecs - minutes * 60

  return (
    <Fragment key={nextSecs}>
      <Helmet defer={false} titleTemplate={`%s - ${APP_TITLE}`} defaultTitle={APP_TITLE}>
        {info && !info.is_finished && (
          <title>
            {{ working: '🚀', interval: '⏳', rest: '🏖️' }[info.interval]}{' '}
            {`${padLeftStrng(minutes)}:${padLeftStrng(seconds)}`}
          </title>
        )}
      </Helmet>
    </Fragment>
  )
}
