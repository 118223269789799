import { FunctionComponent, ReactNode } from 'react'
import { tickSelector } from '../../recoil/timer'
import type { TimerViewport } from '../../types/viewport'
import ClockFrame from '../molecules/ClockFrame'
import ClockHands from '../molecules/ClockHands'
import { TimerGauge } from './gauge/TimerGauge'

// tslint:disable: object-literal-sort-keys
export const DEFAULT_VIEWPORT = {
  top: 0,
  left: 0,
  right: 120,
  bottom: 120,
  center: {
    x: 60,
    y: 60,
  },
  width: 120,
  height: 120,
}

export interface TimerClockProps {
  className?: string
  viewport?: TimerViewport
  stroke?: number
  time?: Date
  children?: ReactNode
}

export default function TimerClock({ viewport = DEFAULT_VIEWPORT, className }: TimerClockProps) {
  return (
    <div className={className}>
      <svg
        viewBox={`${viewport.top} ${viewport.left} ${viewport.width} ${viewport.height}`}
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <ClockComponent viewport={viewport} />
      </svg>
    </div>
  )
}

interface ClockComponentProps {
  viewport: TimerViewport
  children?: ReactNode
}

const ClockComponent: FunctionComponent<ClockComponentProps> = (props) => {
  const clockConfig = {
    fill: '#ffdd00',
    stroke: '#ff0000',
    frameColor: '#ffffff',
  }
  const radius = props.viewport.center.x - 10

  return (
    <g>
      <ClockFrame viewport={props.viewport} radius={radius} frameColor={clockConfig.frameColor} fill={clockConfig.fill}>
        <TimerGauge viewport={props.viewport} radius={radius} />
      </ClockFrame>
      <ClockHands tickState={tickSelector} viewport={props.viewport} color={clockConfig.stroke} radius={radius} />
      {props.children}
    </g>
  )
}
