import { ReactNode, useEffect, useRef } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { RecoilValueReadOnly, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from 'recoil'

type RecoilResolverValueBase = {
  initialize?: () => Promise<void>
}

export interface RecoilResolverProps<T extends RecoilResolverValueBase | unknown> {
  state: RecoilValueReadOnly<T>
  deps?: unknown[]
  children?: ReactNode | ((value: T) => ReactNode)
}

export const RecoilResolver = <T extends RecoilResolverValueBase | unknown>({
  state,
  deps = [],
  children,
}: RecoilResolverProps<T>) => {
  const errorHandler = useErrorHandler()
  const value = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(state)
  const ref = useRef<boolean>(false)

  useEffect(() => {
    if (ref.current || typeof value !== 'object') {
      return
    }
    ref.current = true

    Promise.resolve((value as RecoilResolverValueBase).initialize?.())
      .catch((err) => errorHandler(err))
      .finally(() => {
        ref.current = false
      })
    return () => {
      ref.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandler, ...deps])

  return <>{children instanceof Function ? children(value) : children}</>
}
