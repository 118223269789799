import { Auth } from 'aws-amplify'
import { AuthStorage } from './auth-storage'

Auth.configure({
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

  // REQUIRED - Amazon Cognito Region
  region: process.env.REACT_APP_AWS_REGION,

  // OPTIONAL - Amazon Cognito Federated Identity Pool Region
  // Required only if it's different from Amazon Cognito Region
  // identityPoolRegion: 'XX-XXXX-X',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: 'USER_SRP_AUTH',

  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  // clientMetadata: { myCustomKey: 'myCustomValue' },

  // OPTIONAL - Hosted UI configuration
  oauth: {
    domain: process.env.REACT_APP_COGNITO_UI_DOMAIN,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: `${process.env.REACT_APP_URL}/login`,
    redirectSignOut: process.env.REACT_APP_URL,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  storage: AuthStorage,
})
