import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
};

export type Mutation = {
  __typename?: 'Mutation';
  checkTimer?: Maybe<Timer>;
  createTimer: Timer;
  createTimerConfig: TimerConfig;
  createUser: Scalars['Boolean'];
  finishTimer: Scalars['Boolean'];
};


export type MutationCheckTimerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTimerArgs = {
  timer: TimerInput;
};


export type MutationCreateTimerConfigArgs = {
  timer_config: TimerConfigInput;
};


export type MutationFinishTimerArgs = {
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  timer?: Maybe<Timer>;
  timerConfig?: Maybe<TimerConfig>;
};

export type Timer = {
  __typename?: 'Timer';
  config_id: Scalars['ID'];
  end_time: Scalars['DateTime'];
  id: Scalars['ID'];
  is_finished?: Maybe<Scalars['Boolean']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  prev_id?: Maybe<Scalars['ID']>;
  reps: Scalars['Int'];
  start_time: Scalars['DateTime'];
};

export type TimerConfig = {
  __typename?: 'TimerConfig';
  focus_minutes: Scalars['Int'];
  id: Scalars['ID'];
  interval_minutes: Scalars['Int'];
  reps: Scalars['Int'];
  rest_minutes: Scalars['Int'];
};

export type TimerConfigInput = {
  focus_minutes: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  interval_minutes: Scalars['Int'];
  reps: Scalars['Int'];
  rest_minutes: Scalars['Int'];
};

export type TimerInput = {
  config_id: Scalars['ID'];
  end_time: Scalars['DateTime'];
  id?: InputMaybe<Scalars['ID']>;
  prev_id?: InputMaybe<Scalars['ID']>;
  reps: Scalars['Int'];
  start_time: Scalars['DateTime'];
};

export type GetTimerAndConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTimerAndConfigQuery = { __typename?: 'Query', timer?: { __typename?: 'Timer', id: string, start_time: string, end_time: string, reps: number, prev_id?: string | null, config_id: string, is_finished?: boolean | null, is_valid?: boolean | null } | null, timerConfig?: { __typename?: 'TimerConfig', id: string, focus_minutes: number, interval_minutes: number, reps: number, rest_minutes: number } | null };

export type CurrentTimerQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentTimerQuery = { __typename?: 'Query', timer?: { __typename?: 'Timer', id: string, start_time: string, end_time: string, reps: number, prev_id?: string | null, config_id: string, is_finished?: boolean | null, is_valid?: boolean | null } | null };

export type CreateTimerMutationMutationVariables = Exact<{
  input: TimerInput;
}>;


export type CreateTimerMutationMutation = { __typename?: 'Mutation', createTimer: { __typename?: 'Timer', id: string, start_time: string, end_time: string, reps: number, prev_id?: string | null, config_id: string } };

export type FinishTimerMutationMutationVariables = Exact<{
  input: Scalars['ID'];
}>;


export type FinishTimerMutationMutation = { __typename?: 'Mutation', finishTimer: boolean };

export type CheckTimerMutationMutationVariables = Exact<{
  input: Scalars['ID'];
}>;


export type CheckTimerMutationMutation = { __typename?: 'Mutation', checkTimer?: { __typename?: 'Timer', id: string, start_time: string, end_time: string, reps: number, prev_id?: string | null, config_id: string, is_finished?: boolean | null, is_valid?: boolean | null } | null };

export type MyTimerConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTimerConfigQuery = { __typename?: 'Query', timerConfig?: { __typename?: 'TimerConfig', id: string, focus_minutes: number, interval_minutes: number, reps: number, rest_minutes: number } | null };

export type MyTimerConfigMutationMutationVariables = Exact<{
  input: TimerConfigInput;
}>;


export type MyTimerConfigMutationMutation = { __typename?: 'Mutation', createTimerConfig: { __typename?: 'TimerConfig', id: string, interval_minutes: number, focus_minutes: number, reps: number, rest_minutes: number } };

export type CreateUserMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateUserMutationMutation = { __typename?: 'Mutation', createUser: boolean };


export const GetTimerAndConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTimerAndConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"timer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"start_time"}},{"kind":"Field","name":{"kind":"Name","value":"end_time"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"prev_id"}},{"kind":"Field","name":{"kind":"Name","value":"config_id"}},{"kind":"Field","name":{"kind":"Name","value":"is_finished"}},{"kind":"Field","name":{"kind":"Name","value":"is_valid"}}]}},{"kind":"Field","name":{"kind":"Name","value":"timerConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"focus_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"interval_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"rest_minutes"}}]}}]}}]} as unknown as DocumentNode<GetTimerAndConfigQuery, GetTimerAndConfigQueryVariables>;
export const CurrentTimerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentTimer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"timer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"start_time"}},{"kind":"Field","name":{"kind":"Name","value":"end_time"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"prev_id"}},{"kind":"Field","name":{"kind":"Name","value":"config_id"}},{"kind":"Field","name":{"kind":"Name","value":"is_finished"}},{"kind":"Field","name":{"kind":"Name","value":"is_valid"}}]}}]}}]} as unknown as DocumentNode<CurrentTimerQuery, CurrentTimerQueryVariables>;
export const CreateTimerMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTimerMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TimerInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTimer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"timer"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"start_time"}},{"kind":"Field","name":{"kind":"Name","value":"end_time"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"prev_id"}},{"kind":"Field","name":{"kind":"Name","value":"config_id"}}]}}]}}]} as unknown as DocumentNode<CreateTimerMutationMutation, CreateTimerMutationMutationVariables>;
export const FinishTimerMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FinishTimerMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"finishTimer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<FinishTimerMutationMutation, FinishTimerMutationMutationVariables>;
export const CheckTimerMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CheckTimerMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkTimer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"start_time"}},{"kind":"Field","name":{"kind":"Name","value":"end_time"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"prev_id"}},{"kind":"Field","name":{"kind":"Name","value":"config_id"}},{"kind":"Field","name":{"kind":"Name","value":"is_finished"}},{"kind":"Field","name":{"kind":"Name","value":"is_valid"}}]}}]}}]} as unknown as DocumentNode<CheckTimerMutationMutation, CheckTimerMutationMutationVariables>;
export const MyTimerConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MyTimerConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"timerConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"focus_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"interval_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"rest_minutes"}}]}}]}}]} as unknown as DocumentNode<MyTimerConfigQuery, MyTimerConfigQueryVariables>;
export const MyTimerConfigMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MyTimerConfigMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TimerConfigInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTimerConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"timer_config"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"interval_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"focus_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"rest_minutes"}}]}}]}}]} as unknown as DocumentNode<MyTimerConfigMutationMutation, MyTimerConfigMutationMutationVariables>;
export const CreateUserMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateUserMutation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUser"}}]}}]} as unknown as DocumentNode<CreateUserMutationMutation, CreateUserMutationMutationVariables>;