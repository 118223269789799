import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { FC, ReactNode, Suspense, useCallback, useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { AuthHeaderItem } from '../organisms/action/AuthHeaderItem'
import { ThemeSelector } from '../organisms/header/ThemeSelector'

export interface MainTemplateProps {
  title: string
  children?: ReactNode
}

export const MainTemplate: FC<MainTemplateProps> = ({ title, children }) => {
  const menuRef = useRef<HTMLIonMenuElement>(null)
  const { signOut } = useAuthenticator((context) => [context.user])

  const handleRefresh = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonItem slot="end" lines="none" color="none">
            <Suspense fallback={<></>}>
              <ThemeSelector />
              <AuthHeaderItem onClickUser={() => menuRef.current?.open()} />
            </Suspense>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen id="main-content">
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <ErrorBoundary fallback={<>Error</>}>
          <Suspense>{children}</Suspense>
        </ErrorBoundary>
      </IonContent>

      <IonMenu ref={menuRef} side="end" type="overlay" contentId="main-content">
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>User</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem button onClick={signOut}>
              Logout
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
    </IonPage>
  )
}
