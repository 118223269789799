import { useAuthenticator } from '@aws-amplify/ui-react'
import { IonItem, IonLabel } from '@ionic/react'
import { CircleText } from '../../atoms/CircleText'

export type AuthHeaderItemProps = {
  onClickUser?: () => void
}

export const AuthHeaderItem = ({ onClickUser }: AuthHeaderItemProps) => {
  const { user } = useAuthenticator((context) => [context.user])

  const isLogin = Boolean(user)
  return (
    <IonItem routerLink={isLogin ? undefined : '/login'} lines="none">
      {isLogin ? (
        <button onClick={onClickUser}>
          <CircleText text={user.username} />
        </button>
      ) : (
        <IonLabel>Login</IonLabel>
      )}
    </IonItem>
  )
}
