import { isPlatform } from '@ionic/react'
import { Drivers, Storage } from '@ionic/storage'
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import { DatabaseName } from '.'

const isNative = isPlatform('ios') || isPlatform('android')

export const authStorage = new Storage({
  name: DatabaseName,
  storeName: 'auth',
  driverOrder: [isNative ? CordovaSQLiteDriver._driver : Drivers.IndexedDB, Drivers.LocalStorage],
})
authStorage.create()
