import React, { Suspense } from 'react'
import { TimerActions } from '../components/organisms/action/TimerActions'
import TimerClock from '../components/organisms/TimerClock'
import { MainTemplate } from '../components/templates/MainTemplate'

const Home: React.FC = () => {
  return (
    <MainTemplate title="Timer">
      <div className="w-full h-full flex flex-col items-center justify-center">
        <Suspense fallback={<></>}>
          <TimerClock className="w-4/5 sm:w-3/5 md:w-2/5" />
          <TimerActions />
        </Suspense>
      </div>
    </MainTemplate>
  )
}

export default Home
