import { Authenticator } from '@aws-amplify/ui-react'
import { IonButton, IonItem } from '@ionic/react'
import React from 'react'
import { RecoilResolver } from '../components/organisms/utils/RecoilResolver'
import { PageTemplate } from '../components/templates/PageTemplate'
import { timerStateInializer } from '../recoil/timer'

const Login: React.FC = () => {
  return (
    <PageTemplate title="Login">
      <div className="flex items-center justify-center h-full">
        <Authenticator loginMechanisms={['email']} socialProviders={['google']}>
          {({ signOut, user }) => (
            <RecoilResolver state={timerStateInializer}>
              <IonItem lines="none" className="mt-4">
                <IonButton color="primary" size="default" type="submit" onClick={() => signOut?.(user)}>
                  Logout
                </IonButton>
              </IonItem>
            </RecoilResolver>
          )}
        </Authenticator>
      </div>
    </PageTemplate>
  )
}

export default Login
