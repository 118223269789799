import { useRecoilValue } from 'recoil'
import { timerConfigSelector } from '../recoil/timer'
import { timerActions } from '../recoil/timerActions'

export const useTimeConfig = () => {
  const timerConfig = useRecoilValue(timerConfigSelector)
  const { saveConfig } = useRecoilValue(timerActions)

  return {
    timerConfig,
    saveConfig,
  }
}
