import { authStorage } from '../storage/auth'

export class AuthStorage {
  // the promise returned from sync function
  static syncPromise: Promise<void> | null = null
  static dataMemory: Record<string, string> = {}

  // set item with the key
  static setItem(key: string, value: string): string {
    authStorage.set(key, value)
    AuthStorage.dataMemory[key] = value
    return AuthStorage.dataMemory[key]
  }
  // get item with the key
  static getItem(key: string) {
    return Object.prototype.hasOwnProperty.call(AuthStorage.dataMemory, key) ? AuthStorage.dataMemory[key] : undefined
  }
  // remove item with the key
  static removeItem(key: string): void {
    delete AuthStorage.dataMemory[key]
    authStorage.remove(key)
  }
  // clear out the storage
  static clear(): void {
    AuthStorage.dataMemory = {}
    authStorage.forEach((val, key) => {
      authStorage.remove(key)
    })
  }

  static async getAll() {
    const items: { key: string; value: string }[] = []
    await authStorage.forEach((val, key) => {
      items.push({ key, value: String(val) })
    })
    return items
  }

  // If the storage operations are async(i.e AsyncStorage)
  // Then you need to sync those items into the memory in this method
  static sync(): Promise<void> {
    if (!AuthStorage.syncPromise) {
      AuthStorage.syncPromise = new Promise((res, rej) => {
        AuthStorage.getAll()
          .then((data) => {
            data.forEach(({ key, value }) => {
              AuthStorage.dataMemory[key] = value
            })
            console.log('Sycn with webext')
          })
          .catch((err) => rej(err))
          .finally(() => res())
      })
    }
    return AuthStorage.syncPromise
  }
}
