import { IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import { FC, ReactNode, Suspense } from 'react'
import { ThemeSelector } from '../organisms/header/ThemeSelector'

export interface HomeTemplateProps {
  title: string
  children?: ReactNode
}

export const PageTemplate: FC<HomeTemplateProps> = ({ title, children }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonItem slot="end" lines="none" color="none">
            <Suspense fallback={<></>}>
              <ThemeSelector />
            </Suspense>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>{children}</IonContent>
    </IonPage>
  )
}
