import { FC, useCallback, useMemo } from 'react'
import type { TimerViewport } from '../../../../types/viewport'
import clockUtils from '../../../../utils/clock-utils'

export interface NormalGaugeProps {
  viewport: TimerViewport
  radius: number
  start: number
  end: number
}

const multiplyXY = (obj: { x: number; y: number }, times: number) => {
  return {
    x: obj.x * times,
    y: obj.y * times,
  }
}

export const NormalGauge: FC<NormalGaugeProps> = ({ viewport, radius, start, end }) => {
  const center = viewport.center
  const makeDPath = useCallback(
    (center: { x: number; y: number }, start: number, end: number) => {
      const startMinutes = clockUtils.getFineMinutesWithTimestamp(start)
      const innerCurStart = clockUtils.getXY(clockUtils.getTimeDeg60(startMinutes))

      const curMinutes = clockUtils.getFineMinutesWithTimestamp(end)
      const innerCurEnd = clockUtils.getXY(clockUtils.getTimeDeg60(curMinutes))

      const curStart = multiplyXY(innerCurStart, radius)
      const curEnd = multiplyXY(innerCurEnd, radius)
      const thirtyMinutes = 30 * 60 * 1000
      const diff = end - start
      let d = `M${center.x} ${center.y} ${center.x + curStart.x} ${center.y + curStart.y}`
      let lastPosition = {
        x: center.x + curStart.x,
        y: center.y + curStart.y,
      }
      for (let count = Math.floor(diff / thirtyMinutes); count >= 0; count -= 1) {
        if (count > 0) {
          const diagonalX = center.x + (center.x - lastPosition.x)
          const diagonalY = center.y + (center.y - lastPosition.y)
          d += ` A50 50 0 0 1 ${diagonalX} ${diagonalY}`
          lastPosition = {
            x: diagonalX,
            y: diagonalY,
          }
        } else {
          d += ` A50 50 0 0 1 ${center.x + curEnd.x} ${center.y + curEnd.y}Z`
        }
      }
      return d
    },
    [radius]
  )

  const curD = useMemo(() => {
    return makeDPath(center, start, end)
  }, [center, end, makeDPath, start])

  return (
    <g>
      <mask id="timerCircleMask">
        <circle cx={center.x} cy={center.y} r="50" fill="white" />
        <circle cx={center.x} cy={center.y} r="38" fill="black" />
      </mask>
      {curD && <path d={curD} fill="currentColor" mask="url(#timerCircleMask)" />}
    </g>
  )
}
