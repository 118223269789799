import { useAudio } from '../../hooks/useAudio'
import { useTimerAutomation } from '../../hooks/useTimer'
import { timerStateInializer } from '../../recoil/timer'
import { TimerActionHead } from './head/TimerActionHead'
import { RecoilResolver } from './utils/RecoilResolver'

export const AppModule = () => {
  useTimerAutomation()
  const { props: bellProps } = useAudio('bell')
  const { props: tickProps } = useAudio('tick')

  return (
    <>
      <audio {...bellProps} />
      <audio {...tickProps} />
      <TimerActionHead />
      <RecoilResolver state={timerStateInializer} />
    </>
  )
}
