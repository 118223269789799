import React, { PropsWithChildren } from 'react'
import type { TimerViewport } from '../../types/viewport'
import NormalFrame from '../atoms/frames/normal'

export interface ClockFrameProps {
  viewport: TimerViewport
  radius: number
  frameColor: string
  fill: string
}

export default function ClockFrame(props: PropsWithChildren<ClockFrameProps>) {
  return <NormalFrame {...props} />
}
