import { useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { themeState } from '../recoil/config'

// Add or remove the "dark" class based on if the media query matches
const toggleDarkTheme = (shouldAdd: boolean) => {
  document.body.classList.toggle('dark', shouldAdd)
}

export const useTheme = () => {
  const [systemTheme, setSystemTheme] = useState<'light' | 'dark'>()
  const theme = useRecoilValue(themeState)

  const updateTheme = useRecoilCallback(({ set }) => (value?: 'light' | 'dark') => {
    set(themeState, value)
  })

  /**
   * Set system color scheme change events
   */
  useEffect(() => {
    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
    setSystemTheme(prefersDark.matches ? 'dark' : 'light')
    const toggleTheme = (mediaQuery: MediaQueryListEvent) => {
      if (theme) {
        return
      }
      toggleDarkTheme(mediaQuery.matches)
      setSystemTheme(mediaQuery.matches ? 'dark' : 'light')
    }
    toggleDarkTheme(theme ? theme === 'dark' : prefersDark.matches)
    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addEventListener('change', toggleTheme)
    return () => {
      prefersDark.removeEventListener('change', toggleTheme)
    }
  }, [theme])

  return {
    theme,
    systemTheme,
    updateTheme,
  }
}
