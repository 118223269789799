import { cacheExchange, createClient, dedupExchange, fetchExchange } from '@urql/core'
import { authExchange } from '@urql/exchange-auth'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import type { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'

export const getClient = () => {
  return createClient({
    url: process.env.REACT_APP_ENDPOINT_URL || '',
    exchanges: [
      dedupExchange,
      requestPolicyExchange({
        ttl: 30 * 1000,
      }),
      cacheExchange,
      authExchange<{ session: CognitoUserSession }>({
        addAuthToOperation: ({ authState, operation }) => {
          if (!authState?.session) {
            return operation
          }
          const token = authState.session.getIdToken().getJwtToken()
          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {}

          operation.context.fetchOptions = {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: `Bearer ${token}`,
            },
          }
          return operation
        },
        willAuthError: ({ authState }) => {
          if (!authState || !authState.session.isValid()) return true
          return false
        },
        didAuthError: ({ error }) => {
          return error.response?.status === 401
        },
        getAuth: async ({ authState }) => {
          try {
            const session = await Auth.currentSession()
            if (session.isValid()) {
              return { session }
            }

            await Auth.signOut()

            return null
          } catch (err) {
            console.warn(err)
            return null
          }
        },
      }),
      fetchExchange,
    ],
  })
}
