"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionCanceledExceptionCode = exports.ConditionalCheckFailedExceptionCode = exports.RequestLimitExceededCode = exports.ValidationExceptionCode = exports.UnrecognizedClientExceptionCode = exports.ThrottlingExceptionCode = exports.ServiceUnavailableCode = exports.ProvisionedThroughputExceededExceptionCode = exports.ResourceNotFoundExceptionCode = exports.InternalServerErrorCode = exports.DynamodbErrorCode = exports.NoUserErrorCode = exports.ApplicationErrorCode = void 0;
// App error
exports.ApplicationErrorCode = 'ApplicationError';
exports.NoUserErrorCode = 'NoUserError';
// Dynamodb error
exports.DynamodbErrorCode = 'DynamodbError';
exports.InternalServerErrorCode = 'InternalServerError';
exports.ResourceNotFoundExceptionCode = 'ResourceNotFoundException';
exports.ProvisionedThroughputExceededExceptionCode = 'ProvisionedThroughputExceededException';
exports.ServiceUnavailableCode = 'ServiceUnavailable';
exports.ThrottlingExceptionCode = 'ThrottlingException';
exports.UnrecognizedClientExceptionCode = 'UnrecognizedClientException';
exports.ValidationExceptionCode = 'ValidationException';
exports.RequestLimitExceededCode = 'RequestLimitExceeded';
exports.ConditionalCheckFailedExceptionCode = 'ConditionalCheckFailedException';
exports.TransactionCanceledExceptionCode = 'TransactionCanceledException';
